import CtaWpp from "../../components/CTAWpp";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import header3_1 from "../../assets/header3_1.png";
import header3_2 from "../../assets/header3_2.png";
import header3_3 from "../../assets/header3_3.png";
import header3_4 from "../../assets/header3_4.png";
import header3_5 from "../../assets/header3_5.png";
import header3_6 from "../../assets/header3_6.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: header3_1,
    title: "Desentupimento de pias",
    text: "Desentupimento de pias realizado com rapidez e eficiência por profissionais qualificados, garantindo o perfeito funcionamento de suas instalações.",
  },
  {
    id: 2,
    image: header3_2,
    title: "Desentupimento de vaso sanitário",
    text: "Desentupimento de vaso sanitário com eficiência e rapidez, garantindo sua total satisfação e conforto.",
  },
  {
    id: 3,
    image: header3_3,
    title: "Desentupimento de esgoto",
    text: "Desentupimento de esgoto com eficácia e rapidez, proporcionando soluções confiáveis e duradouras para a sua rede de saneamento, com foco na sua comodidade e bem-estar.",
  },
  {
    id: 4,
    image: header3_4,
    title: "Desentupimento e limpeza de caixa de gordura",
    text: "Excelência em desentupimento de caixa de gordura oferece diagnóstico preciso, atendimento 24h, equipe qualificada e equipamentos modernos, garantindo eficiência e satisfação do cliente.",
  },
  {
    id: 5,
    image: header3_5,
    title: "Desentupimento de rede de água pluvial",
    text: "Resolvemos obstruções de redes de água pluvial de forma eficiente, garantindo escoamento perfeito e evitando inundações, com atendimento ágil e especializado.",
  },
  {
    id: 6,
    image: header3_6,
    title: "Limpeza de fossas (sucção)",
    text: "Realizamos o serviço de limpeza de fossas  com o processo de sucção dos dejetos, de forma que evite o desconforto o mal cheiro  em seu lar ou empresa.",
  },
];

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">
            <span>Entupiu?</span> Chame a <br /> <b> LT Desentope!</b>
          </h1>

          <p className="text">
            Somos uma empresa especializada em desentupimento de pias, vasos
            sanitários, esgoto, rede de água pluvial, além de limpeza de fossas
            e caixas de gordura.
          </p>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container vertical">
          <h2 className="title">
            ATENDEMOS EMPRESAS, RESIDÊNCIAS, CONDOMÍNIOS E APARTAMENTOS
          </h2>

          <p className="text">
            Contamos com uma equipe de profissionais qualificados e
            comprometidos com a entrega de um serviços de qualidade, com
            agilidade e excelência!
          </p>
        </article>
      </section>

      <section className="header3">
        <article className="container vertical">
          <h2 className="title">NOSSOS SERVIÇOS</h2>

          {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) ? (
            <div className="gallery">
              {data.map(({ id, image, title, text }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <div className="item">
                      <figure className="top">
                        <img src={image} alt={`Exemplo ${id} de Foto`} />
                      </figure>

                      <div className="bottom">
                        <p className="item_title">{title}</p>

                        <p className="item_text">{text}</p>

                        <CtaWpp />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>
          ) : (
            <Swiper
              className="carousel"
              spaceBetween={20}
              slidesPerView={3}
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data.map(({ id, image, title, text }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <div className="item">
                      <figure className="top">
                        <img src={image} alt={`Exemplo ${id} de Foto`} />
                      </figure>

                      <div className="bottom">
                        <p className="item_title">{title}</p>

                        <p className="item_text">{text}</p>

                        <CtaWpp />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </article>
      </section>
    </header>
  );
};

export default Header;
