import "./styles.css";

import CtaTel from "../../components/CTATel";
import CtaWpp from "../../components/CTAWpp";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about2_1 from "../../assets/about2_1.png";
import about2_2 from "../../assets/about2_2.png";
import about2_3 from "../../assets/about2_3.png";
import about3 from "../../assets/about3.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">POR QUE NOS ESCOLHER?</h2>

          <p className="text">
            Nosso diferencial é poder oferecer o melhor serviços aos nossos
            clientes, com:
          </p>

          <div className="gallery">
            <div className="item">
              <figure className="left">
                <img src={about1_1} alt="Pontualidade" />
              </figure>

              <div className="right">
                <p className="item_title">Pontualidade</p>

                <p className="item_text">
                  Atendimento profissional com agilidade e excelência.
                </p>
              </div>
            </div>

            <div className="item">
              <figure className="left">
                <img src={about1_2} alt="Atendimento 24h" />
              </figure>

              <div className="right">
                <p className="item_title">Atendimento 24h</p>

                <p className="item_text">
                  Oferecemos atendimento 24h, com agilidade e excelência.
                </p>
              </div>
            </div>

            <div className="item">
              <figure className="left">
                <img src={about1_3} alt="Profissionalismo" />
              </figure>

              <div className="right">
                <p className="item_title">Profissionalismo</p>

                <p className="item_text">
                  Contamos com profissionais altamente capacitados.
                </p>
              </div>
            </div>

            <div className="item">
              <figure className="left">
                <img src={about1_4} alt="Atendimento personalizado" />
              </figure>

              <div className="right">
                <p className="item_title">Atendimento personalizado</p>

                <p className="item_text">
                  Atendimento de acordo com a necessidade de cada cliente.
                </p>
              </div>
            </div>
          </div>

          <div className="bottom">
            <p className="text">Ligue agora ou fale conosco via WhatsApp:</p>

            <div className="cta">
              <CtaTel />
              <CtaWpp text={"(41) 99675-1663"} />
            </div>
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container vertical">
          <h2 className="title">COMO TRABALHAMOS</h2>

          <p className="text">
            Com equipamentos modernos conseguimos localizar o ponto exato da
            obstrução, evitando a quebra desnecessária de pisos e paredes, o que
            torna o custo total do reparo do desentupimento muito mais em conta.
          </p>

          <p className="text1">
            Veja em 3 passos como solicitar nossos serviços:
          </p>

          <div className="gallery">
            <div className="item">
              <figure className="top">
                <img src={about2_1} alt="Passo 1" />
              </figure>

              <p className="item_text">
                Fale conosco por telefone ou WhatsApp. Nossa equipe estará
                pronta para lhe atender.
              </p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about2_2} alt="Passo 2" />
              </figure>

              <p className="item_text">
                Nossa equipe irá até você para fazer o orçamento, sem
                compromisso, de acordo com o serviços desejado.
              </p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about2_3} alt="Passo 3" />
              </figure>

              <p className="item_text">
                Caso esteja de acordo, realizamos o serviço de imediato, para
                garantir o seu conforto e bem-estar.
              </p>
            </div>
          </div>

          <p className="text1">Solicite agora mesmo um orçamento!</p>

          <div className="cta">
            <CtaTel />
            <CtaWpp text={"(41) 99675-1663"} />
          </div>
        </div>
      </article>

      <article className="about3">
        <div className="container vertical">
          <h2 className="title">formas de pagamento</h2>

          <p className="text">
            Aceitamos cartão de crédito, débito, pix e espécie.
          </p>

          <figure>
            <img src={about3} alt="Formas de Pagamento" />
          </figure>
        </div>
      </article>
    </section>
  );
};

export default About;
